import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";


class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: true // Dodanie stanu isMenuOpen i ustawienie domyślnej wartości na false
    };
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  render() {
    const handleLanguageChange = (lang) => {
      this.props.onChangeLanguage(lang);
      };

    if (!this.props.data) return null;

    const name = this.props.data.name;
    const napis = this.props.data.napis;
    const etykietaContactMenu = this.props.data.etykietaContactMenu;
    const etykietaOffer = this.props.data.etykietaOffer;
    const etykietaProject = this.props.data.etykietaProject;
    const project = this.props.data.project;

    return (
      <header id="home">
        <ParticlesBg type="circle" bg={true} />
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation" onClick={this.handleMenuClose}>
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation" onClick={this.handleMenuOpen}>
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                {etykietaOffer}
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about" style={{ marginRight: '200px'}}>
                {etykietaContactMenu}
              </a>
            </li>
                
                <li>
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                  <img
                    src="pl.png"
                    alt="Polish Flag"
                    className="language-flag"
                    style={{ cursor: "pointer", width: "20px" }}
                    onClick={() => handleLanguageChange("pl")}
                  />
                  &nbsp; &nbsp;
                  <img
                    src="en.png"
                    alt="English Flag"
                    className="language-flag"
                    style={{ cursor: "pointer", width: "25px" }}
                    onClick={() => handleLanguageChange("en")}
                  />
                  </div>
                </li>
                {this.state.isMenuOpen && ( // Renderowanie dodatkowych elementów tylko gdy menu jest otwarte
              <>
              <li>
                  <div style={{ marginLeft: '40px'}}>
                    <p style={{ color: "black", fontSize: "12px" }}>v. 1.0.1</p>
                  </div>
                </li>
              </>
            )}

          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <img className="logo_na_banerze" src="images/logo_bez_textu.png"></img>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{napis}</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
                <a href={project} className="button btn project-btn" target="_blank">
                  <i className="fa fa-book"></i>{etykietaProject}
                </a>
            </Fade>
          </div>
        </div>
        
        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;