import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const nameAll = this.props.data.nameAll;
    const profilepic = "images/logo_bialy_text.png";
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const email = this.props.data.email;
    const etykietaContact = this.props.data.etykietaContact;
    const etykietaAbout = this.props.data.etykietaAbout;
    const nip = this.props.data.nip;
    const regon = this.props.data.regon;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Nordic Giant Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
            <h2>{etykietaAbout}</h2>
            <p>{bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>{etykietaContact}</h2>
                  <p className="address">
                    <span>{nameAll}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {zip} {city}
                      <br />
                      {state}
                    </span>
                    <br />
                    <span>{email}</span>
                    <br />
                    <span>{nip}</span>
                    <br />
                    <span>{regon}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
