import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQZDBTC1k2SRb7vYQ6NIs_NEpeIqs155o",
  authDomain: "stronafirmowa-b5689.firebaseapp.com",
  projectId: "stronafirmowa-b5689",
  storageBucket: "stronafirmowa-b5689.appspot.com",
  messagingSenderId: "491473453801",
  appId: "1:491473453801:web:fffbe402f364e28a3ce678",
  measurementId: "G-L910N6JM0B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {},
      language: "pl" // Domyślny język
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: `./text_${this.state.language}.json`, // Ładuje dane z odpowiedniego pliku JSON na podstawie wybranego języka
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  handleChangeLanguage = (language) => {
this.setState({ language }, () => {
this.getResumeData(); // Po zmianie języka, pobierz nowe dane
});
};

  render() {
    return (
      <div className="App">

        {/* Renderujemy Header */}
        <Header data={this.state.resumeData.main} language={this.state.language} 
         onChangeLanguage={this.handleChangeLanguage} // Przekaż onChangeLanguage
                  />

        {/* Pozostałe komponenty */}
        <About data={this.state.resumeData.main}/>
        <Footer data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
