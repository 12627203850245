import React from 'react';
import './index.css';
import App from './App';
import ReactDOMServer from 'react-dom';
import reportWebVitals from './reportWebVitals'; // Importuj reportWebVitals

// Przerób ReactDOM.render na createRoot
const root = ReactDOMServer.createRoot(document.getElementById('root'));

// Renderowanie aplikacji
root.render(<App />);

// Wywołanie reportWebVitals
reportWebVitals();
